.illustration-philosophy {
    width: 38%;
    display: flex;
    justify-content: center; /* Center illustration */
    align-items: center;
    margin-top: -100px;
  }
  
  .illustration-philosophy img {
    height: auto;
  }

  .text-normal-philosophy  {
    color: #000000; /* Highlight color */
    font-size: 23px;
    font-weight: 300;
    max-width: 700px;
    margin-top: 40px;
    line-height: 1.4;
  }

  @media (max-width: 768px) {

    .illustration-philosophy {
        margin-bottom: 20px; /* Add spacing below the image */
        margin-top: -50px;
      }
    
      .illustration-philosophy img {
        min-width: 300px; /* Adjust image size for smaller screens */
        height: auto;
        margin-top: -50px;
      }
      .text-normal-philosophy {
        text-align: left;
        font-size: 16px;
        color: #000000;
        line-height: 1.7;
        margin-top: 20px;
        font-weight: 400;
      }
    

  }
