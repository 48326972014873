.text-normal-design {
  color: #525252; /* Highlight color */
  font-size: 35px;
  font-weight: 300;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.text-yellow-design {
  color: #fcd34d; /* Highlight color */
  font-size: 99px;
  padding-left: 0px;
  margin-left: -3px;
  margin-top: -10px;
  text-align: left;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.left-header-design {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  text-align: left;
}

.ilustration-design {
  min-width: 300px;
  max-height: 600px;
  width: 100%;
  max-width: 650px;
  object-fit: contain;
  margin-bottom: -70px;
}
.scroll-icon {
  max-width: 350px;
  animation: bounce 2s infinite;
  margin-top: 20px;
}
.contentRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  height: 74vh;
  margin-bottom: 60px;
}
.contentRow-design {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: end;
  gap: 20px;
  height: 74vh;
}

.grid-picture-style {
  width: 60px;
  height: auto;
}

.grid-section-design {
  display: grid;
  grid-template-rows: 350px 300px 350px;
  grid-template-columns: 1fr 0.05fr 0.05fr 0.05fr 0.05fr 0.15fr 0.8fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  row-gap: 20px;
  height: auto;
  max-width: 1240px;
  margin-top: 100px;
}

.feature-row1-card1-design {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  grid-column: 1/5;
  background-color: #171717;
  color: white;
  border-radius: 0 10px 0 0;
  height: 100%;
  opacity: 0;
}

.feature-row1-card1-design.visible {
  animation: fadeInRight 1s forwards ease;
}
.feature-row1-card2-design.visible {
  animation: fadeInRight 1s forwards ease;
}
.feature-row2-card1-design.visible {
  animation: fadeInLeft 1s forwards ease;
}
.feature-row2-card2-design.visible {
  animation: fadeInLeft 1s forwards ease;
}
.feature-row3-card1-design.visible {
  animation: fadeInRight 1s forwards ease;
}
.feature-row3-card2-design.visible {
  animation: fadeInRight 1s forwards ease;
}

.feature-row1-card1-content-design {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  text-align: left;
  max-width: 400px;
  width: 70%;
}

.feature-row1-card2-design {
  display: flex;
  grid-column: 6/8;
  color: white;
  align-self: end;
  justify-self: start;
  align-items: end;
  justify-content: start;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.feature-row1-card2-content-design {
  width: 70%;
  height: 60%;
  background-color: #fbbf24;
  border-radius: 10px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-row1-2 {
  height: 50%;
}

.feature-row2-card1-design {
  grid-row: 2;
  grid-column: 1/3;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 100%;
  margin-left: 10px;
  opacity: 0;
}
.feature-row2-card1-content-design {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 70%;
  padding: 20px;
  text-align: left;
}

.feature-row2-card2-design {
  grid-row: 2;
  grid-column: 4/8;
  background-color: #e5e5e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 10px 0px 0px 0px;
  opacity: 0;
}

.feature-row2-card2-content-design {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  width: 70%;

  text-align: right;
}
.feature-row3-card1-design {
  grid-row: 3;
  grid-column: 1/4;
  background-color: #171717;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 0px 10px 10px 0px;
  opacity: 0;
}

.feature-row3-card1-content-design {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 70%;
  padding: 20px;
  text-align: left;
}

.feature-row3-card2-design {
  display: flex;
  grid-row: 3;
  grid-column: 5/8;

  color: white;
  opacity: 0;
  align-self: start;
  justify-self: start;
  width: 100%;
  height: 100%;
}

.feature-row3-card2-content-design {
  width: 80%;
  height: 60%;
  border-radius: 10px 10px 10px 10px;
  background-color: #fbbf24;
  padding: 20px 0px 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-title-style {
  font-family: Plus Jakarta Sans;
  font-weight: 600;
  font-size: 30px;
  margin: 15px 0px;
}
.card-p-style {
  font-family: Plus Jakarta Sans;
  font-weight: 400;
  font-size: 15px;
}

.cta-button-modular {
  border: 3px solid #fbbf24;
  background-color: transparent;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #fbbf24;
  font-size: 40px;
  font-weight: 500;
  padding: 20px 55px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cta-button-modular:hover {
  background-color: #fbbf24;
  color: #fff;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 880px) {
  .grid-section-design {
    grid-template-rows: 280px 140px 270px 320px 140px 402px;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
  }

  .feature-row1-card1-design {
    grid-column: 1;
    grid-row: 1;
  }
  .feature-row1-card2-design {
    grid-column: 1;
    grid-row: 2;
    width: 100%;
    height: 100%;
  }
  .feature-row2-card1-design {
    grid-column: 1;
    grid-row: 4;
    margin-right: 10px;
  }
  .feature-row2-card2-design {
    grid-column: 1;
    grid-row: 3;
  }
  .feature-row3-card1-design {
    grid-column: 1;
    grid-row: 6;
  }
  .feature-row3-card2-design {
    grid-column: 1;
    grid-row: 5;
    width: 100%;
    height: 100%;
  }
  .grid-row1-2 {
    height: 90%;
  }

  .feature-row1-card1-content-design {
    width: 90%;
  }
  .feature-row2-card1-content-design {
    width: 90%;
  }
  .feature-row3-card1-content-design {
    width: 90%;
  }
  .feature-row2-card2-content-design {
    width: 90%;
  }

  .feature-row3-card2-content-design {
    width: 100%;
    height: 100%;
  }
  .feature-row1-card2-content-design {
    width: 100%;
    height: 100%;
  }

  .text-normal-design {
    font-size: 18px; /* Smaller text for mobile */
    color: #000000;
    font-weight: 400;
  }

  .text-yellow-design {
    font-size: 68px; /* Adjusted for mobile */
    font-weight: 600;
    margin-top: -20px;
  }
  .scroll-icon {
    max-width: 250px;
  }

  .contentRow {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;
  }
  .left-header-design {
    align-self: flex-start;
  }

  .card-title-style {
    font-size: 25px;
  }

  .ilustration-design {
    max-height: 397px;
    width: 100%;
    margin-bottom: 0px;
  }

  .contentRow-design {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .contentRow-design {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .cta-button-modular {
    font-size: 17px;
    padding: 20px 15px;
  }
}

@media (max-width: 600px) {
  .left-header-design {
    padding-left: 0px;
  }
}
