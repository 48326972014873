.navbar {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: start;
  width: 100%;
}

.navbar,
.navbar * {
  box-sizing: border-box;
}

.navbar_container {
  /* max-width: 1440px; */
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  z-index: 4;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 70px;
}

.nav_logo {
  margin: 25px;
  width: 15%;
  max-width: 50px;
  height: auto;
  cursor: pointer;
  padding-top: 10px;
}

.nav_items {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  width: 77vw;
  max-width: 600px;
  gap: 1px;
  min-width: 500px;
  padding-right: 19px;
  text-wrap: nowrap;
}
.nav_item {
  cursor: pointer;
  color: black;
  transition: 0.3s ease;
}
.nav_item:hover {
  transition: 0.3s ease;
  color: #fbbf24;
  font-weight: 600;
}

.nav_toggle {
  background: none;
  border: 0;
  z-index: 3;
}

.nav_item_active {
  color: #fbbf24;
  cursor: pointer;
}

.linkedin-mobile-link .linkedin-container {
  width: 50px;
  height: 50px;
  background-image: url("../../img/linkedin.svg");
  background-size: cover;
  background-position: center;
}

@media (max-width: 880px) {
  .nav_items {
    position: fixed;
    top: 0;
    left: 105vw;
    padding-top: 110px;
    padding-right: 0px;
    font-size: 18px;
    z-index: 2;
    height: 100%;
    min-width: 300px;
    max-width: none;
    width: 105vw;
    flex-direction: column;
    align-items: start;
    padding-left: 30px;
    background-color: rgb(252, 252, 252);
    justify-content: start;
    gap: 30px;
    background-size: cover;
    transition: 0.3s;
    transform: translateX(-101%);
  }

  .nav_item {
    font-size: 48px;
    text-align: left;
  }

  .nav_item_active {
    font-size: 48px;
  }

  .nav_toggle {
    display: inline-block;
    margin-right: 30px;
  }

  .responsive_nav {
    transform: none;
  }

  .nav_logo {
    margin: 15px;
    width: 10%;
    min-width: 70px;
    height: auto;
  }
  .linkedin-container {
    margin-left: -50px;
  }
}
