.title-black-case{
  font-size: 102px;
  font-weight: 700;
  color: #0A0A0A;
  font-family: "Plus Jakarta Sans";
  text-align: left;
}
.paragraph-case{
  color: #0A0A0A;
  font-family: "Plus Jakarta Sans";
  font-size: 17px;
  font-weight: 400;
}

@media screen and (max-width: 880px){
  .title-black-case{
    font-size: 68px;
    font-weight: 700;
    color: #0A0A0A;
    font-family: "Plus Jakarta Sans";
    text-align: left;
  }
  .paragraph-case{
    color: #0A0A0A;
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-weight: 400;
  }
  
}