.card7 {
  font-family: "Plus Jakarta Sans", sans-serif;
  background-color: #171717;
  height: 450px;
  margin: auto;
  width: 100%;
  border-radius: 50px 50px 40px 40px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card7-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 80%;
}

.card7-image {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.card7 h2 {
  font-size: 40px;
  font-weight: 400;
  margin-bottom: 20px;
}

.card7 p {
  font-size: 17px;
  font-weight: 400;
  margin-top: 30px;
  line-height: 1.5;
}

@media (max-width: 768px) {
  div.card7 {
    padding-bottom: 280px;
    border-radius: 50px 50px 0 0;
  }
  .card7-content img {
    max-width: 90%; /* Make the image responsive */
    height: auto;
    padding-top: 130px;
    padding-bottom: 20px;
  }

  .card7-content {
    max-width: 90%;
  }

  div.card7-content h2 {
    font-size: 29px; /* Decrease the font size on mobile */
    margin-bottom: 10px;
  }

  .card7 p {
    text-align: center; /* Center the paragraph on mobile */
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 0;
    font-size: 13px;
    font-weight: 400;
  }
}
