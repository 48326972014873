.homepage_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.homepage_container, 
.homepage_container * {
  box-sizing: border-box;
}

.image-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensures the popup appears above other content */
  }

  .zoomed-image {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    transition: transform 0.3s ease; /* Smooth transition for zoom */
  }
  
  .close-popup {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2em;
    color: #ffffff;
    cursor: pointer;
    z-index: 1001; /* Above the popup background */
  }
  
  .zoom-controls {
    position: absolute;
    bottom: 30px;
    display: flex;
    gap: 10px; /* Spacing between buttons */
    z-index: 1002; /* Above the popup background */
  }
  
  .zoom-button {
    padding: 10px 20px;
    width: 50px;
    font-size: 1em;
    background-color: #000000;
    color: #ffffff;
    border: 0.5px solid #1a1a1a;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .zoom-button:hover {
    background-color: #2e2e2e; /* Lighter color on hover */
  }