.contactus {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: white;
}

.logoRow-contactus {
  display: flex;
  justify-content: left;
  margin-bottom: 40px;
  margin-left: 100px;
  margin-top: 100px;
}

.contentRow-contactus {
  margin-top: 50px;
  display: flex;
  justify-content: space-between; /* Align text and illustration side by side */
  align-items: center;
  height: 75vh;
  margin-left: 100px;
  margin-right: 100px;
}

div.textContent-contactus {
  flex: 1; /* Takes up available space */
  font-size: 25px;
  line-height: 1.1;
  width: 20%;
  color: black;
  font-family: "Plus Jakarta Sans", sans-serif;
  margin-left: 20px;
  margin-top: -20px;
  max-width: 500px;
}

.text-normal-contactus {
  color: #000000; /* Highlight color */
  font-size: 23px;
  font-weight: 300;
  max-width: 500px;
  margin-top: 40px;
}

.text-yellow-contactus {
  font-size: 125px;
  max-width: 500px;
}

.illustration-contactus {
  width: 35%;
  display: flex;
  justify-content: center; /* Center illustration */
  align-items: center;
  margin-top: 0px;
}

.illustration-contactus img {
  height: auto;
}
.contactInfoSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 1300px;
}

.formGrid {
  display: grid;
  grid-template-columns: 1fr; /* One field per row by default */
  gap: 20px;
}

.contactDetail {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 25px;
}

.contactDetail img {
  width: 70px;
  height: auto;
}

.contactDetail h3 {
  color: #fbbf24;
  font-weight: 500;
  font-size: 30px;
  margin: 0 30px 10px 30px;
}

.contactDetail p {
  font-weight: 500;
  color: #000000;
  margin: 0px 30px 10px 30px;
}

.map img {
  width: 100%;
  max-width: 500px;
}

.contactFormSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
  width: 100%; /* Allow the section to adjust dynamically */
  max-width: 1000px; /* Maintain the maximum width for larger screens */
  margin: 50px auto; /* Center horizontally */
  padding: 20px; /* Add padding for spacing */
  background-color: #fff; /* Keep the white background */
}

.contactForm {
  flex: 2;
  max-width: 900px; /* Limit width for larger screens */
  font-family: "Plus Jakarta Sans", sans-serif;
}

.contactForm h2 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #fbbf24; /* Yellow color for headings */
  font-weight: bold;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.formGroup label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #000; /* Black for labels */
}

.formGroup input,
.phoneInputData {
  padding: 10px;
  border: 2px solid #fbbf24; /* Yellow border */
  border-radius: 4px;
  font-size: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
  outline: none;
}

.formGroup textarea {
  padding: 10px;
  border: 2px solid #fbbf24;
  border-radius: 4px;
  font-size: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
  outline: none;
  resize: vertical; /* Allow resizing */
}

.submitButton {
  padding: 12px 24px;
  background-color: #fbbf24; /* Yellow background for button */
  color: #171717; /* Dark gray text */
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submitButton:hover {
  background-color: #e5a520; /* Slightly darker yellow on hover */
}

.successMessage {
  color: #28a745; /* Green for success */
  font-weight: bold;
  margin-top: 10px;
}

.errorMessage {
  color: #dc3545; /* Red for error */
  font-weight: bold;
  margin-top: 10px;
}

.formGroup > input,
.phoneInputData {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2px solid #fbbf24;
  font-size: 16px;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 98%;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.formGroup > input:focus,
.phoneInputData:focus,
.formGroup > textarea:focus {
  outline: none;
}

.phoneInput {
  display: flex;
  align-items: center;
  gap: 10px;
}

.phoneInput img {
  height: 20px;
}

.flagSelect > button {
  border: none;
}
.ReactFlagsSelect-module_filterBox__3m8EU > input {
  border: none;
  border-bottom: 1px solid #fbbf24;
  font-size: 10px;
}

.ReactFlagsSelect-module_label__27pw9 {
  font-size: 14px !important;
  padding-left: 5px !important;
}

ul.ReactFlagsSelect-module_selectOptions__3LNBJ.ReactFlagsSelect-module_selectOptionsWithSearch__1W03.ReactFlagsSelect-module_fullWidthOptions__1XR6 {
  padding: 10px 30px;
}

.ReactFlagsSelect-module_fullWidthOptions__1XeR6 {
  width: 90px;
}

@media (max-width: 768px) {
  .contactus {
    padding: 10px;
    margin-bottom: 200px;
  }

  .contentRow-contactus {
    margin-top: 20px;
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center content horizontally */
    align-items: center;
    height: auto; /* Adjust height to fit content */
    margin: 0 20px; /* Add some margin for spacing */
  }

  div.textContent-contactus {
    width: 100%; /* Take full width on smaller screens */
    text-align: left; /* Center the text */
    margin: 20px 0; /* Add vertical spacing */
    font-size: 20px; /* Adjust font size for smaller screens */
    line-height: 1.3;
  }

  .text-yellow-contactus {
    font-size: 50px; /* Smaller font size for mobile */
    margin-bottom: 10px; /* Add spacing below the heading */
  }

  .illustration-contactus {
    width: 100%; /* Make the image take full width */
    margin-bottom: 20px; /* Add spacing below the image */
  }

  .illustration-contactus img {
    max-width: 80%; /* Adjust image size for better fit */
    height: auto;
  }

  .scroll-down-section {
    margin-bottom: 250px;
    margin-top: 50px;
    align-self: flex-start; /* Aligns the item to the start of the cross-axis in a flex container */
    text-align: left; /* Aligns text or inline content to the left */
  }

  .formGroup > input {
    width: 95%;
  }

  .formGrid {
    grid-template-columns: 1fr;
  }

  .map img {
    max-width: 100%;
  }

  .contactFormSection {
    flex-direction: column; /* Stack content vertically */
    align-items: center;
    gap: 20px;
    padding: 10px;
    width: 95%; /* Take full width on smaller screens */
    max-width: 500px; /* Keep it visually consistent */
  }

  .contactForm {
    max-width: 100%;
    width: 100%;
  }

  .formGrid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .submitButton {
    width: 100%; /* Make the button take full width */
  }

  .ReactFlagsSelect-module_selectValue__152eS svg {
    width: 45px;
    height: 25px;
    margin-left: -20px;
  }
  .contactDetails {
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 20px;
    width: 85%;
  }
  .contactDetail {
    display: flex;
    align-self: flex-start;
    flex-direction: column; /* Stack image and text vertically */
    align-items: left; /* Center items horizontally */
    text-align: left; /* Center-align text */
    justify-content: left;
    gap: 10px; /* Add space between image and text */
    margin-bottom: 25px;
  }

  .contactDetail img {
    width: 70px;
    height: auto;
    align-self: start;
  }

  .contactDetail h3 {
    color: #fbbf24;
    font-weight: 500;
    font-size: 20px;
    margin: 10px 0 5px 0;
  }

  .contactDetail p {
    font-weight: 500;
    color: #000000;
    margin: 0;
    align-self: start;
  }
}
