.project-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  margin: 0 auto;
  max-width: 900px;
  text-align: left;
  gap: 50px;
  margin: 50px 0px;
}

.left-project-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 2rem;
  max-width: 700px;
  width: 100%;
}

.project-title {
  color: #000;
  font-family: "Plus Jakarta Sans";
  font-size: 65px;
  text-align: start;
}

.project-description {
  color: #0a0a0a;
  font-family: "Plus Jakarta Sans";
  font-size: 20px;
  font-weight: 400;
}

.grid-project-images {
  display: grid;
  grid-template-columns: 1fr 0.6fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  column-gap: 15px;
  row-gap: 15px;
  width: 100%;
}
.grid-project-images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid-project-images :nth-child(1) {
  grid-row: 1;
  grid-column: 1;
}
.grid-project-images :nth-child(2) {
  grid-row: 1;
  grid-column: 2/4;
}
.grid-project-images :nth-child(3) {
  grid-row: 2;
  grid-column: 1/3;
}
.grid-project-images :nth-child(4) {
  grid-row: 2;
  grid-column: 3;
}
.grid-project-images :nth-child(5) {
  grid-row: 3;
  grid-column: 1;
}
.grid-project-images :nth-child(6) {
  grid-row: 3;
  grid-column: 2/4;
}

@media screen and (max-width: 880px) {
  .project-container {
    flex-direction: column;
    margin: 20px 0px;
  }
  .left-project-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 2rem;
    width: 100%;
    padding: 0px 10px;
  }

  .project-title {
    font-size: 40px;
  }

  .project-description {
    font-size: 15px;
  }
}
