.card4 {
  display: flex;
  font-family: "Plus Jakarta Sans", sans-serif;
  height: 550px;
  margin: auto;
  margin-bottom: 0;
  width: 100%;
  padding-bottom: 200px;
}

.card4-column-left {
  background-color: #E5E5E5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 50%;
  text-align: center;
  padding: 20px;
  border-radius: 50px 0 0 0;
}

.card4-column-left img {
  width: 70px;
  height: 80px;
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
  margin-bottom: 30px;
  margin-top: 20px;
}

.card4-column-right {
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 50%;
  text-align: center;
  padding: 20px;
  border-radius: 0 50px 0 0;
  padding-top: 60px;
}

.card4-column-right img {
  width: 73px;
  height: 58px;
  max-width: 30%;
  max-height: 300px;
  object-fit: contain;
  margin-bottom: 30px;
}

.card4 h2 {
  font-size: 31px;
  font-weight: 400;
  margin-bottom: 10px;
}

.card4 p {
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  max-width: 80%;
  margin-top: 30px;
  line-height: 1.5;
}

.card4-column-left,
.card4-column-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* Mobile-specific changes */
@media (max-width: 768px) {
  .card4 {
    flex-direction: column; /* Stack .card4-column-left above .card4-column-right */
    height: auto; /* Adjust height based on content */
  }

  .card4-column-left, .card4-column-right {
    width: 100%; /* Full width for both columns on mobile */
    text-align: center;
    padding: 20px;
  }

  .card4-column-left {
    border-radius: 50px 50px 0 0; /* Rounded corners for the top section */
    padding: 0;
    padding-bottom: 20px;
  }

  div.card4-column-left h2{
    font-size: 25px;
  }

  .card4-column-right {
    padding: 0;
    border-radius: 0 0 0 0; /* Rounded corners for the bottom section */
    padding-bottom: 80px; /* Adjust padding for mobile */
    height: 900px;
    margin-bottom: 400px;
  }

  div.card4-column-right h2{
    font-size: 25px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .card4-column-left img,
  .card4-column-right img {
    max-width: 50px; /* Adjust image size for mobile */
    height: auto;
    margin-bottom: 10px;
    margin-top: 25px;
  }

  .card4 h2 {
    font-size: 24px; /* Adjust heading size for mobile */
  }

  .card4 p {
    font-size: 14px; /* Adjust paragraph size for mobile */
    margin-top: 15px;
    margin-left: 8px;
    margin-right: 8px;
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    }
}
