div.card2 {
  display: flex;
    font-family: "Plus Jakarta Sans", sans-serif;
    background: linear-gradient(to right, white, #F5F5F5);
      height: 450px;
      margin: auto;
      margin-bottom: 0;
      text-align: left;
      width: 100%;
      border-radius: 50px 50px 0 0;
      padding-bottom: 200px;
    }

    .card2-left {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      background-color: white;
      border-radius: 50px 0 0 0;
    }

    .card2-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: left;
      flex: 1;
      background-color: #F5F5F5;
      color: black;
      border-radius: 0 50px 0 0;
  
    }
    .card2 h2{
      font-size: 31px;
      font-weight: 400;
      margin-left: 10%;
    }
    .card2 p{
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      margin-left: 10%;
      margin-right: 10%;
      margin-top: 30px;
      line-height: 1.5;

    }

    .card2-left img {
      max-width: 433px;
      max-height: 300px;
      object-fit: contain;
    }
    
    @media (max-width: 768px) {
      div.card2{
        height: 600px;
        padding-bottom: 50px;
        text-align: center;
        background: linear-gradient(to right, #F5F5F5, #F5F5F5);

      }

      .card2 {
        flex-direction: column; /* Stack the elements vertically on mobile */
        height: 600px; /* Allow the height to adjust based on content */
        padding-bottom: 50px; /* Adjust padding for mobile */
        text-align: center;
      }
    
      .card2-left {
        width: 100%; /* Make the left section take full width */
        border-radius: 50px 50px 0 0; /* Adjust the border radius for top stacking */
      }
    
      .card2-right {
        width: 100%; /* Make the right section take full width */
        margin-top: 20px; /* Add some spacing between the two sections */
        border-radius: 0 0 0 0; /* Adjust the border radius for bottom stacking */
        text-align: center; /* Center the text for better readability on mobile */
        justify-content: center;
        align-items: center;
        padding-bottom: 90px;

      }
      
      div.card2-right h2{
        padding-top: 10px;
        font-size: 25px;
      }

    
      .card2-left img {
        max-width: 100%; /* Make sure the image doesn't overflow */
        height: auto; /* Keep the aspect ratio intact */
        object-fit: contain; /* Ensure the image fits within the container */
        padding-top: 30px;
        padding-bottom: 30px;
      }
    
      .card2 h2 {
        text-align: center; /* Center the title on mobile */
        margin-left: 0;
      }
    
      .card2 p {
        text-align: center; /* Center the paragraph on mobile */
        margin-left: 5px;
        margin-right: 5px;
        font-size: 14px;
      }
    }