.card3 {
  display: flex;
  font-family: "Plus Jakarta Sans", sans-serif;
  background: linear-gradient(to right, #171717, white);
  height: 450px;
  margin: auto;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
  border-radius: 50px 50px 0 0;
  padding-bottom: 200px;
}

.card3-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 50%;
  background-color: #171717;
  color: white;
  border-radius: 50px 0 0 0;
}

.card3-left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 50%;
  background-color: white;
  border-radius: 0 50px 0 0;
  padding-top: 70px;
}

.card3 h2 {
  font-size: 48px;
  font-weight: 400;
  margin-top: 20px;
  padding: 0 130px;
}

.card3 p {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 30px;
  line-height: 1.5;
}

.card3-left img {
  max-width: 333px;
  max-height: 300px;
  object-fit: contain;
}

/* Mobile-specific changes */
@media (max-width: 768px) {
  .card3 {
    flex-direction: column; /* Stack the elements vertically on mobile with .card3-right above .card3-left */
    height: auto; /* Allow content to adjust the height */
    padding-bottom: 50px; /* Adjust padding for mobile */
    background: linear-gradient(to right, white, white);


  }

  .card3-right, .card3-left {
    width: 100%; /* Make both sections take full width on mobile */
    text-align: center; /* Center the text for mobile readability */
    align-items: center;
    justify-content: center;
  }

  .card3-right {
    border-radius: 50px 50px 0 0;
  }

  .card3-left {
    padding-top: 10px;
  }

  div.card3-right h2 {
    border-radius: 0;
    padding-top: 70px;
    font-size: 25px;
    text-align: center;
  }

  .card3 h2 {
    text-align: center; /* Center the title on mobile */
    margin-left: 0;
    padding: 50px 60px 20px 60px;
    margin-top: 70px;
  }
  .card3 p {
    text-align: center; /* Center the paragraph on mobile */
    margin-left: 5px;
    margin-right: 5px;
    font-size: 14px;
    font-weight: 300;
    padding-bottom: 50px;
  }

  .card3-left img {
    max-width: 70%; /* Make the image responsive */
    height: auto; /* Maintain the aspect ratio */
    margin-top: 60px;
    margin-bottom: 50px;
  }
}
