.text-yellow-modular-second {
  color: #fcd34d; /* Highlight color */
  font-weight: 600;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 90px;
}

.ilustration-modular {
  position: relative;
  top: 0;
  left: 0;
}

.grid-section-modular {
  display: grid;
  grid-template-rows: 300px 350px;
  grid-template-columns: 0.6fr 0.05fr 0.05fr 0.1fr 0.05fr 0.4fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  row-gap: 20px;
  height: auto;
  max-width: 1240px;
}

.feature-row1-card1-modular {
  grid-column: 1/5;
  grid-row: 1;
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px 10px 0px 0px;
  opacity: 0;
}
.feature-row1-card2-modular {
  grid-column: 6/8;
  grid-row: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: start;
  opacity: 0;
}
.feature-row2-card2-modular {
  grid-column: 3/8;
  grid-row: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: start;
  opacity: 0;
}

.feature-row2-card1-modular {
  grid-column: 1/2;
  grid-row: 2;
  background-color: #171717;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: white;
  opacity: 0;
}

.feature-row1-card1-content-modular {
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  border-radius: 10px;
  text-align: left;
}
.feature-row2-card1-content-modular {
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  border-radius: 10px;
  text-align: left;
}

.feature-row1-card2-content-modular {
  background-color: #e5e5e5;
  width: 80%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.feature-row1-card2-content-modular img {
  height: 50%;
  width: auto;
}
.feature-row2-card2-content-modular {
  background-color: #fbbf24;
  width: 100%;
  height: 70%;
  display: flex;
  padding: 20px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  text-align: right;
  border-radius: 10px 0px 0px 0px;
}

.feature-row1-card1-modular.visible {
  animation: fadeInRight 1s forwards ease;
}
.feature-row1-card2-modular.visible {
  animation: fadeInRight 1s forwards ease;
}
.feature-row2-card1-modular.visible {
  animation: fadeInLeft 1s forwards ease;
}
.feature-row2-card2-modular.visible {
  animation: fadeInLeft 1s forwards ease;
}

@media screen and (max-width: 880px) {
  .text-yellow-modular-second {
    font-size: 38px;
  }
  .grid-section-modular {
    grid-template-rows: 300px 140px 300px 400px;
    grid-template-columns: 1fr;
  }
  .feature-row1-card1-modular {
    grid-column: 1;
    grid-row: 1;
  }
  .feature-row1-card2-modular {
    grid-column: 1;
    grid-row: 2;
  }
  .feature-row1-card2-content-modular {
    height: 100%;
    width: 100%;
  }

  .feature-row2-card1-modular {
    grid-column: 1;
    grid-row: 4;
  }
  .feature-row2-card2-modular {
    grid-column: 1;
    grid-row: 3;
  }
  .feature-row2-card2-content-modular {
    height: 100%;
  }
}
