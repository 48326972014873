.grid-section-ai {
  display: grid;
  grid-template-rows: 300px 25px 25px 25px 250px;
  grid-template-columns: 4fr 2fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  column-gap: 25px;
  max-width: 1240px;
}


.feature-row1-card1-ai{
  grid-column: 1;
  grid-row: 1/2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #E5E5E5;
  border-radius: 0 10px 0 0;
  opacity: 0;
}
.feature-row1-card1-content-ai{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 80%;
  height: 80%;
  text-align: left;
}



.feature-row1-card2-ai{
  grid-column: 2; 
  grid-row: 1/4;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #171717;
  color: white;
  border-radius: 10px 0 0 0;
  opacity: 0;
}
.feature-row1-card2-content-ai{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  width: 80%;
  height: 80%;
  text-align: right;
}



.feature-row2-card1-ai{
  grid-column: 1; 
  grid-row: 3/6;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FBBF24;
  border-radius: 10px;
  margin-left: 10px;
  opacity: 0;
}
.feature-row2-card1-content-ai{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 80%;
  height: 80%;
  text-align: left;
}

.feature-row2-card2-ai{
  grid-column: 2; 
  grid-row: 5/6;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  opacity: 0;
}
.feature-row2-card2-content-ai{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F5F5F5;
  border-radius: 10px;
  width: 90%;
  height: 45%;
  padding: 20px;
  text-align: left;
}

.feature-row1-card1-ai.visible{
  animation: fadeInRight 1s forwards ease;
}
.feature-row1-card2-ai.visible{
  animation: fadeInLeft 1s forwards ease;
}
.feature-row2-card1-ai.visible{
  animation: fadeInRight 1s forwards ease;
}
.feature-row2-card2-ai.visible{
  animation: fadeInLeft 1s forwards ease;
}

@media screen and (max-width: 880px){
  .grid-section-ai {
    grid-template-rows: 300px 300px 140px 300px;
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
  
  .feature-row1-card1-ai{
    grid-column: 1;
    grid-row: 1;
  }
  .feature-row1-card2-ai{
    grid-column: 1;
    grid-row: 2;
  }
  .feature-row2-card1-ai{
    grid-column: 1;
    grid-row: 4;
  }
  .feature-row2-card2-ai{
    grid-column: 1;
    grid-row: 3;
  }
  .feature-row2-card2-content-ai{
    height: 100%;
    width: 100%;
  }


}


@media screen and (max-width: 410px){
  .grid-section-ai {
    grid-template-rows: 370px 330px 140px 300px;
  }}