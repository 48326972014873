div.card11 {
  display: flex;
  font-family: "Plus Jakarta Sans", sans-serif;
  background-color: #FBBF24;
  height: 450px;
  margin: auto;
  margin-bottom: 0;
  text-align: left;
  width: 100%;
  border-radius: 50px 50px 0 0;
  padding-bottom: 200px;
}

.card11-left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: white;
  border-radius: 50px 0 0 0;
}

.card11-left img {
  max-width: 433px;
  max-height: 300px;
  object-fit: contain;
}

.card11-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: #171717;
  color: white;
  border-radius: 0 50px 0 0;
}

.card11-right img{
  width: 93px;
  height: 78px;
  max-width: 30%;
  max-height: 300px;
  object-fit: contain;
  margin-bottom: 25px;
}

.card11 h2 {
  font-size: 31px;
  font-weight: 500;
  margin-left: 0;
  align-self: center;
}

.card11 p {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 30px;
  line-height: 1.5;
}

/* Mobile-specific changes */
@media (max-width: 768px) {
  div.card11 {
    margin-top: 110px;
    flex-direction: column; /* Stack .card11-left above .card11-right */
    height: auto; /* Allow the height to adjust based on content */
    padding-bottom: 90px; /* Adjust padding for mobile */
    background: linear-gradient(to right, #171717, #171717);
  }

  .card11-left,
  .card11-right {
    width: 100%; /* Make both sections take full width on mobile */
    text-align: center; /* Center the text for mobile readability */
  }

  .card11-left {
    border-radius: 50px 50px 0 0; /* Rounded corners for the top section */
  }

  .card11-right {
    border-radius: 0 0 0 0; /* Rounded corners for the bottom section */
    padding-bottom: 70px;
  }

  .card11-left img {
    max-width: 90%; /* Make the image responsive */
    padding: 40px 40px 40px 40px;
    height: auto;
  }

  .card11-right img {
    padding: 80px 40px 10px 40px;
    max-width: 20%;
    height: auto;
  }

  div.card11 h2 {
    text-align: center; /* Center the title on mobile */
    margin-left: 0;
    font-size: 25px;
    padding-top: 0px;
  }

  .card11 p {
    text-align: center; /* Center the paragraph on mobile */
    margin-left: 5px;
    margin-right: 5px;
    font-size: 14px;
    font-weight: 300;
    padding: 0 10px 0 10px;
  }
}
