@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');


*{
  padding: 0;
  margin: 0;
}

.App{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  
}

.mobile{
  display: none;
}
.desktop{
  visibility: inline-block;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}

@media (max-width: 880px){
  .mobile{
      display: inline-block;
  }
  .desktop{
      display: none;
  }

}