/* General Styles */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.home {
  display: flex;
  flex-direction: column; /* Arrange in a single column */
  align-items: center; /* Center content horizontally */
  height: 100vh;
  background-color: white;
  padding: 20px;
  overflow: hidden;
}

img,
video,
iframe {
  max-width: 100%;
  height: auto;
}

.mainContent-home {
  width: 100%;
  max-width: 1200px;
}

.logo-home {
  margin-bottom: 10px; /* Add spacing between logo and text */
  width: 350px; /* Adjust logo size for mobile */
  height: auto;
  margin-bottom: 20px;
}

.contentRow-home {
  display: flex;
  justify-content: space-between; /* Align text and illustration side by side */
  align-items: center;
  height: 75vh;
}

.textContent-home {
  flex: 1; /* Takes up available space */
  font-size: 25px;
  line-height: 1.5;
  width: 60%;
  color: black;
  font-family: "Plus Jakarta Sans", sans-serif;
  margin-left: 100px;
}

.textContent-home img {
  width: 230px;
  height: auto;
}

.textContent-home span {
  color: #fcd34d; /* Highlight color */
  font-size: 80px;
}

.text-with-shadow-home {
  text-shadow: none;
}

.illustration-home {
  width: 33%;
  display: flex;
  justify-content: center; /* Center illustration */
  align-items: center;
  margin-top: 100px;
}

.illustration-home img {
  height: 85vh;
}

/* Mobile-Specific Styles */
@media (max-width: 768px) {
  .contentRow-home {
    display: grid; /* Use grid for 2 columns */
    grid-template-columns: 1fr 1fr; /* Two equal columns for text and image */
    gap: 1px; /* Add some space between columns */
    align-items: center; /* Align content vertically */
    margin-top: 20px;
  }

  .textContent-home {
    display: flex;
    flex-direction: column; /* Stack logo and text */
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    font-size: 17px; /* Adjust text size for mobile */
    margin: 0;
    margin-top: 30px;
    line-height: 1.4;
  }

  .logo-home {
    margin-bottom: 10px; /* Add spacing between logo and text */
    width: 150px; /* Adjust logo size for mobile */
    height: auto;
    margin-bottom: 20px;
  }

  .textContent-home span {
    font-size: 54px; /* Adjust text size for mobile */
  }

  .illustration-home {
    display: flex;
    justify-content: center; /* Center image in the column */
    align-items: center;
    margin: 0;
    margin-top: -170px;
    margin-left: -60px;
    width: 70px;
    height: auto;
  }

  .illustration-home img {
    height: 61vh; /* Adjust image size for mobile */
    width: 170px;
  }
}
