.footer_container {
  display: flex;
  justify-content: space-around;
  align-items: start;
  max-width: 1440px;
  width: 100%;
  gap: 20px;
  z-index: 1;
  margin-bottom: 30px;
  margin-top: 150px;
}

.footer_container,
.footer_container * {
  box-sizing: border-box;
}

.gray_footer_item {
  color: #525252 !important;
}
.items_container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: min-content;
  justify-content: space-evenly;
}

.footer_logo {
  max-width: 300px;
  width: 38vw;
  min-width: 300px;
  display: inline;
  margin-right: 30px;
}

.footer_items {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  color: black;
  height: 100px;
  background-color: rgb(255, 255, 255);
  height: auto;
  max-width: 200px;
  gap: 10px;
  z-index: 2;
  margin-left: 20px;
  padding-left: 20px;
}

.foot_item {
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: black;
  text-wrap: nowrap;
  cursor: pointer;
  width: 150px;
}

.foot_item:hover {
  color: #fbbf24;
  transition: 0.3s ease;
}
.gray_footer_item:hover {
  color: #fbbf24 !important;
  transition: 0.3s ease;
}

.footer-paragraph {
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: black;
  text-align: start;
}

.footer-mail {
  margin-top: 20px;
  margin-bottom: 20px;
}

.grow-navs {
  flex-grow: 2;
  gap: 20px;
  z-index: 1;
}
.grow-logo {
  flex-grow: 0.5;
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.grow-contact {
  flex-grow: 1;
  margin-left: 100px;
}

.foot_item_active {
  color: #fbbf24 !important;
  cursor: pointer;
  font-family: Plus Jakarta Sans, sans-serif;
}

.copyright {
  font-family: "Plus Jakarta Sans", sans-serif;
  text-align: center;
  color: #a3a3a3;
  font-size: 1rem;
  font-weight: 300;
  margin-left: 0px;
  margin-bottom: 50px;
}

@media screen and (max-width: 880px) {
  .footer_container {
    height: auto;
    flex-wrap: wrap;
    padding: 0px 10px 0px 10px;
    height: auto;
    justify-content: center;
  }
  .top_line {
    width: 30px;
    height: 1px;
    background-color: #525252;
    margin-bottom: 7px;
  }
  .grow-contact {
    display: flex;
    flex-basis: 20%;
    flex-wrap: wrap;
    flex-grow: 3;
    justify-content: center;
    align-items: center;
    height: auto;
    z-index: 1;
  }
  .footer_items {
    height: auto;
    margin-left: 0px;
    padding-right: 0px;
  }
  .grow-navs {
    flex-basis: 10%;
    flex-wrap: wrap;
    flex-grow: 1;
  }
  .grow-navs div {
    flex-basis: 100%;
    flex-grow: 0;
  }
  .foot_item {
    font-family: Plus Jakarta Sans, sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: black;
    text-wrap: nowrap;
    cursor: pointer;
  }
  .foot_item_active {
    font-size: 15px;
  }
  .grow-logo {
    flex-basis: 100%;
    justify-content: center;
    width: 100%;
    flex-grow: 0;
  }
  .footer_logo {
    width: auto;
    min-width: 0px;
    margin-right: 0px;
  }
  .grow-contact {
    flex-grow: 1;
    margin-left: 0px;
  }
  .footer-paragraph {
    margin-left: -50px;
  }
  .copyright {
    max-width: 100%;
    text-align: center;
    font-size: 14px;
    padding-top: 30px;
    margin-left: 5px;
  }
}
